import React, {useContext, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Typography, AppBar, Toolbar, TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {Link, useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'
import {LoadingContext} from '../context/LoadingContext'

const useStylesTopBar = makeStyles({
  title: {
    textDecoration: 'inherit',
    color: 'inherit',
    marginRight: 'auto',
  },
  autocomplete: {
    '&': {
      color: 'inherit',
      borderColor: 'inherit',
    },
  },
})

const useStylesAutocomplete = makeStyles({
  paper: {
    '& .MuiAutocomplete-option': {
      justifyContent: 'flex-end',
    },
  },
  inputRoot: {
    '&': {
      width: 250,
      color: 'inherit',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit',
      borderWidth: '2px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit',
      borderWidth: '2px',
    },
  },
  clearIndicator: {
    '&': {
      color: 'inherit',
    },
  },
  popupIndicator: {
    '&': {
      color: 'inherit',
    },
  },
})

const TopBar = (props) => {
  const {data, forceFetch, withUserChooser} = props
  const classes = useStylesTopBar()
  const autocompleteClasses = useStylesAutocomplete()
  const {startLoading, stopLoading} = useContext(LoadingContext)
  const {inNameOf, transactionsByJiraId} = data
  const [input, setInput] = useState(inNameOf)
  const [currentUser, setCurrentUser] = useState(inNameOf)
  const history = useHistory()
  const onUserChange = async (newUser) => {
    const inNameOfRequested = newUser
    if (inNameOfRequested === inNameOf) return
    startLoading()
    history.push('/home')
    await forceFetch(inNameOfRequested)
    stopLoading()
  }
  const {jiraId, possibleAccess} = data
    const sortedAccess = [jiraId, ...possibleAccess].sort((a, b) => {
      const aTransactions = transactionsByJiraId[a] || 0
      const bTransactions = transactionsByJiraId[b] || 0
      if (aTransactions === bTransactions) {
        return a.localeCompare(b) // sort alphabetically
      }
      return bTransactions - aTransactions // sort by number of transactions
    })
  const availableAccess = sortedAccess.map((jiraId) => `${jiraId} ${transactionsByJiraId[jiraId] || 0}`)

  return (<AppBar position="sticky">
    <Toolbar>
      <Typography className={classes.title} variant="h6" component={Link} to="/home">
        Card Statements
      </Typography>
      <Autocomplete
        classes={autocompleteClasses}
        inputValue={withUserChooser ? input : ''}
        onInputChange={(event, newInput) => setInput(newInput)}
        value={currentUser}
        onChange={(event, newValue) => {
          newValue = newValue.split(' ')[0]
          setCurrentUser(newValue)
          if (newValue !== null) onUserChange(newValue)}
        }
        options={availableAccess}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
          />)}
        disabled={!withUserChooser}
      />
    </Toolbar>
  </AppBar>)
}

TopBar.propTypes = {
  data: PropTypes.object,
  forceFetch: PropTypes.func,
  withUserChooser: PropTypes.bool,
}

export {TopBar}
