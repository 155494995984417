import React, {Component} from 'react'
import {PropTypes} from 'prop-types'

const ResultContext = React.createContext()

class ResultProvider extends Component {
  state = {
    result: null,
  }

  setResult = (result) => {
    this.setState({result})
  }

  clearResult = () => {
    this.setState({result: null})
  }

  render() {
    return (
      <ResultContext.Provider
        value={{
          result: this.state.result,
          setResult: this.setResult,
          clearResult: this.clearResult,
        }}
      >
        {this.props.children}
      </ResultContext.Provider>
    )
  }
}

ResultProvider.propTypes = {
  children: PropTypes.node,
}

export {ResultContext, ResultProvider}
