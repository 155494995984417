import React, {useContext} from 'react'
import {Backdrop, CircularProgress} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {LoadingContext} from '../context/LoadingContext'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export const LoadingAnimation = () => {
  const classes = useStyles()
  const {isLoading} = useContext(LoadingContext)
  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
