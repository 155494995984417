import React from 'react'
import {Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  header:{
    alignItems: 'center',
    height: '50px',
  },
}))

const Header = (props) => {
  const classes = useStyles()
  return (
    <Grid className={classes.header} container justifyContent="space-between">
      {props.children}
    </Grid>
  )
}

Header.propTypes = {
  children: PropTypes.node,
}

export {Header}
