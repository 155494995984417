import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'
import {PropTypes} from 'prop-types'

const useSubtitleStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 'bold',
    fontSize: 18,
  },
}))

const Subtitle = (props) => {
  const classes = useSubtitleStyles()
  return (<Typography className={classes.text}>
    {props.children}
  </Typography>)
}

Subtitle.propTypes = {
  children: PropTypes.node,
}

export {Subtitle}
