const _ = require('lodash')

// see /server/src/db.js loadPendingManualPairings()

module.exports = _.reduce([
  'id',
  'transaction_uuid',
  'statement_id',
  'createdAt',
  'cardholder',
  'data',
  'files',
  'entity',
  'exportable',
  'processed_by',
], (a, v, k) => {a[v] = k; return a}, {})
