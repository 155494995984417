import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Grid, Box, Typography} from '@material-ui/core'
import $s from '@card-statements/common/statements'
import $t from '@card-statements/common/transactions'
import {formatTimestamp} from '../utils'
import {Subtitle} from '../components/Typography'
import {GridList} from '../components/GridList'
import Toast from '../components/Toast'
import {PageSwitcherHeader} from '../components/PageSwitcherHeader'
import {PropTypes} from 'prop-types'

const useStyles = makeStyles({
  mainGrid: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
  },
  link: {
    textDecoration: 'none',
  },
  span: {
    fontWeight: 'bold',
  },
})

const HistoryPage = (props) => {
  const {data, setWithUserChooser} = props
  const {statements, allTransactions, isFinanceMember} = data
  const sortedStatements = statements.map((statement) => ({
    statement,
    transaction: allTransactions.find((t) => t[$t.uuid] === statement[$s.uuid]),
  }))
  .sort((a, b) => b.statement[$s.createdAt].localeCompare(a.statement[$s.createdAt]))
  const classes = useStyles()

  const [notification, setNotification] = React.useState({severity: undefined, text: undefined})

  const clearNotification = () => setNotification({severity: undefined, text: undefined})

  const excludePageLinks = ['/history'] // exclude self
  if (!isFinanceMember) excludePageLinks.push('/finance-dashboard')
  setWithUserChooser(true)

  return (<>
    <Box className={classes.mainGrid}>
      <PageSwitcherHeader exclude={excludePageLinks}>
        <Subtitle>Recent receipts/invoices</Subtitle>
      </PageSwitcherHeader>
      <GridList>
        {sortedStatements.map((row) => {
          return (
            <Grid
              key={row.statement[$s.uuid]}
              container
            >
              <Grid key={1} item xs={12} md={3} lg={2}>
                UUID:
                <span>
                  {row.statement[$s.uuid]}
                </span>
              </Grid>
              <Grid key={2} item xs={12} md={3} lg={2}>
                Created At:
                <span>
                  {formatTimestamp(row.statement[$s.createdAt])}
                </span>
              </Grid>
              <Grid key={4} item xs={6} md={3} lg={2}>
                Taxable:
                <span>
                  {`${row.statement[$s.taxable]}`}
                </span>
              </Grid>
              <Grid key={5} item xs={12} md={3} lg={1}>
                Amount:
                {/* Adjust this after rebase of table change */}
                <span>
                  {row.transaction ? row.transaction[$t.amountCurrency] : 'N/A'}
                </span>
              </Grid>
              <Grid key={6} item xs={6} md={3} lg={1}>
                Currency:
                {/* Adjust this after rebase of table change */}
                <span>
                  {row.transaction ? row.transaction[$t.currency] : 'N/A'}
                </span>
              </Grid>
              <Grid key={4} item xs={6} md={3} lg={2}>
                Reference:
                <span>
                  {`${row.transaction[$t.reference]}`}
                </span>
              </Grid>
              <Grid key={4} item xs={6} md={3} lg={2}>
                Counterparty:
                <span>
                  {`${row.transaction[$t.counterparty]}`}
                </span>
              </Grid>
              <Grid key={4} item xs={6} md={3} lg={4}>
                Description:
                <span>
                  {`${row.transaction[$t.description]}`}
                </span>
              </Grid>
              <Grid key={7} item xs={6} md={3} lg={1}>
                Files:
                <span>
                  {row.statement[$s.files] && row.statement[$s.files].map(({id}, i) => {
                    return (<React.Fragment key={i}>
                      {i !== 0 && <span>, </span>}
                      <a
                        href={`https://drive.google.com/file/d/${id}/view?usp=sharing`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        #{i + 1}
                      </a>
                    </React.Fragment>)
                  })}
                </span>
              </Grid>
              <Grid key={3} item container xs={12}>
                <Grid key={'title'} item xs={12} md={3} lg={3}>Centers (CC/SubCC/Location/CA/CT/JiraID):</Grid>
                {Object.keys(row.statement[$s.center_split]).map((k) => (
                  <Grid key={k} item xs={12} md={3} lg={5}>
                    <span className={classes.span}>
                      {k}: {row.statement[$s.center_split][k]}
                      &nbsp;{row.transaction && row.transaction[$t.currency]}
                    </span>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )
        })}
      </GridList>
      <Typography>
        <i>Note: center split amounts are in account currency.</i>
      </Typography>
    </Box>
    <Toast
      message={notification.text}
      onClose={clearNotification}
      severity={notification.severity}
    />
  </>)
}

HistoryPage.propTypes = {
  data: PropTypes.object,
  forceFetch: PropTypes.func,
  setWithUserChooser: PropTypes.func,
}

export {HistoryPage}
