import React, {useMemo} from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import AddBoxIcon from '@material-ui/icons/AddBox'
import {makeStyles} from '@material-ui/core/styles'
import {Typography, Button, TextField, Grid, Select, MenuItem} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from  'lodash'
import PropTypes from 'prop-types'
import {decimalFloat} from '../utils'
import DropdownCenter from './DropdownCenter'

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '300px',
  },
  listHolder: {
    'display': 'inline-flex',
    'zIndex': 5,
    '&>div': {
      'padding': theme.spacing(1),
      'borderBottom': '1px solid rgba(220, 220, 220, 1)',
      'borderLeft': '1px solid rgba(220, 220, 220, 1)',
      'borderRight': '1px solid rgba(220, 220, 220, 1)',
      '&>div': {
        padding: '4px',
      },
      '&:first-child': {
        borderTop: '1px solid rgba(220, 220, 220, 1)',
      },
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  lastRow: {
    cursor: 'pointer',
  },
}))

export const DataEntryTable = (props) => {
  const {rows, setRows, currency, setCurrency, totalCostAbs,
    currentSumOfRows, currencyOptions, jiraIdOptions, availableCostTypes,
    centersAllowedCombinations, getValueError} = props

  const [total, totalPercent] = useMemo (() => currency === '%' ?
    [decimalFloat(currentSumOfRows*totalCostAbs/100), decimalFloat(currentSumOfRows)] :
    [decimalFloat(currentSumOfRows), decimalFloat(100*currentSumOfRows/totalCostAbs)],
  [currency, currentSumOfRows, totalCostAbs])
  const classes = useStyles()
  const updateUneditedRows = (arr, curr = currency) => {
    // the rest of the total cost gets divided between unedited fields
    const uneditedSum = Math.max(0,
      (curr === '%' ? 100 : totalCostAbs) -
      _.sum(arr.filter((row) => row.edited).map((row) =>
      // any value is allowed so if value is not a number set value to 0
      (isNaN(parseFloat(row.value)) ? 0 : parseFloat(row.value)))),
    )
    const uneditedNum = arr.filter((row) => !row.edited).length
    let first = true
    const val = decimalFloat(uneditedSum / uneditedNum)
    const tempRows = arr.map((row) => {
      const newRow = {
        ...row,
        value: (row.edited ? row.value : val.toFixed(2)),
      }
      if (first && !row.edited) {
        first = false
        newRow.value = decimalFloat(
          parseFloat(newRow.value) + uneditedSum - val * uneditedNum).toFixed(2)
      }
      return newRow
    })
    setRows(tempRows)
  }

  const isCostTypeRequired = (idx) => {
    const tempRows = [...rows]
    const isRequired = tempRows[idx].costTypeOptions.length > 0
    if (!isRequired) {
      tempRows[idx].costType = null
    }
    return isRequired
  }

  const isJiraIdRequired = (idx) => {
    const tempRows = [...rows]
    const isRequired = (tempRows[idx].costCenter === 'Reinvoicing' &&
    tempRows[idx].subcostCenter === 'People')
    if (!isRequired) {
      tempRows[idx].jiraId = null
    }
    return isRequired
  }

  const handleCostCenterChange = (idx) => (event, newValue) => {
    const tempRows = [...rows]
    tempRows[idx].costCenter = newValue
    tempRows[idx].subcostCenter = null
    tempRows[idx].location = null
    tempRows[idx].costAccount = null
    tempRows[idx].costType = null

    tempRows[idx].locationOptions = []
    tempRows[idx].costAccountOptions = []
    tempRows[idx].costTypeOptions = []

    tempRows[idx].subcostCenterOptions = Object.keys(centersAllowedCombinations[newValue]).filter((option) => option !== 'null')
    tempRows[idx].locationOptions = Object.keys(centersAllowedCombinations[newValue]?.[null] ?? {}).filter((option) => option !== 'null')
    tempRows[idx].costAccountOptions = Object.keys(centersAllowedCombinations[newValue]?.[null]?.[null] ?? {}).filter((option) => option !== 'null')
    if (isCostTypeRequired(idx)) {
      tempRows[idx].costTypeOptions = availableCostTypes
    } else {
      tempRows[idx].costTypeOptions = Object.keys(centersAllowedCombinations[newValue]?.[null]?.[null]?.[null] ?? {}).filter((option) => option !== 'null')
    }

    setRows(tempRows)
  }

  const handleSubcostCenterChange = (idx) => (event, newValue) => {
    const tempRows = [...rows]
    tempRows[idx].subcostCenter = newValue
    tempRows[idx].location = null
    tempRows[idx].costAccount = null
    tempRows[idx].costType = null

    tempRows[idx].costAccountOptions = []
    tempRows[idx].costTypeOptions = []

    tempRows[idx].locationOptions = Object.keys(centersAllowedCombinations[tempRows[idx].costCenter]?.[newValue] ?? {}).filter((option) => option !== 'null')
    tempRows[idx].costAccountOptions = Object.keys(centersAllowedCombinations[tempRows[idx].costCenter]?.[newValue]?.[null] ?? {}).filter((option) => option !== 'null')
    if (isCostTypeRequired(idx)) {
      tempRows[idx].costTypeOptions = availableCostTypes
    } else {
      tempRows[idx].costTypeOptions = Object.keys(centersAllowedCombinations[tempRows[idx].costCenter]?.[newValue]?.[null]?.[null] ?? {}).filter((option) => option !== 'null')
    }

    setRows(tempRows)
  }

  const handleLocationChange = (idx) => (event, newValue) => {
    const tempRows = [...rows]
    tempRows[idx].location = newValue
    tempRows[idx].costAccount = null
    tempRows[idx].costType = null

    tempRows[idx].costTypeOptions = []

    tempRows[idx].costAccountOptions = Object.keys(centersAllowedCombinations[tempRows[idx].costCenter]?.[tempRows[idx].subcostCenter]?.[newValue] ?? {}).filter((option) => option !== 'null')
    if (isCostTypeRequired(idx)) {
      tempRows[idx].costTypeOptions = availableCostTypes
    } else {
      tempRows[idx].costTypeOptions = Object.keys(centersAllowedCombinations[tempRows[idx].costCenter]?.[tempRows[idx].subcostCenter]?.[newValue]?.[null] ?? {}).filter((option) => option !== 'null')
    }

    setRows(tempRows)
  }

  const handleCostAccountChange = (idx) => (event, newValue) => {
    const tempRows = [...rows]
    tempRows[idx].costAccount = newValue
    tempRows[idx].costType = null

    tempRows[idx].costTypeOptions = []

    if (isCostTypeRequired(idx)) {
      tempRows[idx].costTypeOptions = availableCostTypes
    } else {
      tempRows[idx].costTypeOptions = Object.keys(centersAllowedCombinations[tempRows[idx].costCenter]?.[tempRows[idx].subcostCenter]?.[tempRows[idx].location]?.[newValue] ?? {}).filter((option) => option !== 'null')
    }

    setRows(tempRows)
  }

  const handleCostTypeChange = (idx) => (event, newValue) => {
    const tempRows = [...rows]
    tempRows[idx].costType = newValue
    setRows(tempRows)
  }

  // const handleRowAccountChange = (idx) => (event, newValue) => {
  //   const tempRows = [...rows]
  //   tempRows[idx].account = newValue
  //   setRows(tempRows)
  // }
  const handleJiraIdChange = (idx) => (event, newValue) => {
    const tempRows = [...rows]
    tempRows[idx].jiraId = newValue
    setRows(tempRows)
  }

  const handleAddRow = () => {
    const newRow = {
      costCenter: null,
      subcostCenter: null,
      subcostCenterOptions: [],
      location: null,
      locationOptions: [],
      costAccount: null,
      costTypeOptions: [],
      costAccountOptions: [],
      costType: null,
      jiraId: null,
      accounts: [],
      value: 100,
      edited: false,
    }
    updateUneditedRows([...rows, newRow])
  }

  const handleDeleteSpecificRow = (idx) => () => {
    const tempRows = [...rows]
    tempRows.splice(idx, 1)
    updateUneditedRows(tempRows)
  }

  const handleRowCurrencyChange = ({target: {value: currency}}) => {
    setCurrency(currency)
    updateUneditedRows(rows.map((row) => ({
        ...row,
        value: (currency === '%'
          ? decimalFloat(100*row.value/totalCostAbs)   // from val to %
          : decimalFloat(row.value*totalCostAbs/100)), // from % to val
    })), currency)
  }

  const handleRowValueChange = (idx) => ({target: {value: stringValue}}) => {
    const tempRows = [...rows]
    const errorMsg = getValueError(stringValue)
    tempRows[idx] = {
      ...tempRows[idx],
      edited: true,
      value: stringValue,
    }
    if (errorMsg === '') {
      updateUneditedRows(tempRows)
    } else {
      setRows(tempRows)
    }
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid container item justifyContent="center" className={classes.listHolder} xs={12} lg={9}>
        <Grid container item xs={12} className={classes.firstRow}>
          <Typography>
            <span style={total === totalCostAbs ? {} : {color: '#FF4500'}}>
              {`Total: ${total} (${totalPercent}%)`}
            </span>
          </Typography>
        </Grid>
        {rows.map((row, ind) => (
          <Grid
            key={`grid-${ind}`}
            container
            justifyContent="center"
            alignItems="stretch"
            zeroMinWidth
            item
          >
            <Grid key={`table-row-${ind}-0`} item xs={12} sm={6} md lg={2}>
            <DropdownCenter
              options={Object.keys(centersAllowedCombinations)}
              value={row.costCenter}
              onChange={handleCostCenterChange(ind)}
              label="Cost Center"
            />
            </Grid>
            <Grid key={`table-row-${ind}-1`} item xs={12} sm={6} md={3} lg={2}>
            <DropdownCenter
              options={row.subcostCenterOptions}
              value={row.subcostCenter}
              onChange={handleSubcostCenterChange(ind)}
              label="Subcost Center"
              disabled={row.subcostCenterOptions.length === 0}
            />
            </Grid>
              <Grid key={`table-row-${ind}-2`} item xs={12} sm={6} md={3} lg={2}>
              <DropdownCenter
                options={row.locationOptions}
                value={row.location}
                onChange={handleLocationChange(ind)}
                label="Location"
                disabled={row.locationOptions.length === 0}
              />
              </Grid>
            <Grid key={`table-row-${ind}-3`} item xs={12} sm={6} md={3} lg={2}>
            <DropdownCenter
              options={row.costAccountOptions}
              value={row.costAccount}
              onChange={handleCostAccountChange(ind)}
              label="Cost account"
              disabled={row.costAccountOptions.length === 0}
            />
            </Grid>
            <Grid key={`table-row-${ind}-4`} item xs={12} sm={6} md={3} lg={2}>
              <DropdownCenter
                options={row.costTypeOptions}
                value={row.costType}
                onChange={handleCostTypeChange(ind)}
                label="Cost type"
                disabled={!isCostTypeRequired(ind)}
              />
            </Grid>
            <Grid key={`table-row-${ind}-5`} item xs={12} sm={12} md={3} lg={2}>
               <Autocomplete
                options={jiraIdOptions}
                getOptionLabel={(option) => option}
                id="jiraIds"
                value={row.jiraId}
                onChange={handleJiraIdChange(ind)}
                renderInput={(params) => (<TextField
                  {...params}
                  label="Jira ID"
                  variant="outlined"
                  fullWidth
                />)}
                fullWidth
                disabled={!isJiraIdRequired(ind)}
              />
            </Grid>
            <Grid key={`table-row-${ind}-6`} container item xs={12} sm={12} md={3} lg={3} spacing={1} alignItems="stretch" justifyContent="space-between" wrap="nowrap">
              <Grid item>
                <TextField
                  value={row.value}
                  onChange={handleRowValueChange(ind)}
                  variant="outlined"
                  error={getValueError(row.value) !== ''}
                  helperText={getValueError(row.value)}
                  fullWidth
                  inputProps={{style: {textAlign: 'right'}}}
                />
              </Grid>
              <Grid item xs>
                <Select
                  value={currency}
                  onChange={handleRowCurrencyChange}
                  variant="outlined"
                >
                  {currencyOptions.map((opt) => (
                    <MenuItem key={`table-row-${ind}-2-${opt}`} value={opt}>{opt}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item container alignItems="stretch">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDeleteSpecificRow(ind)}
                  disabled={rows.length <= 1}
                >
                  <DeleteIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid container item xs={12} onClick={handleAddRow} className={classes.lastRow} justifyContent="center">
          <AddBoxIcon />
        </Grid>
      </Grid>
    </Grid>
  )
}

DataEntryTable.propTypes = {
  rows: PropTypes.array,
  setRows: PropTypes.func,
  currency: PropTypes.string,
  setCurrency: PropTypes.func,
  totalCostAbs: PropTypes.number,
  currentSumOfRows: PropTypes.number,
  centerOptions: PropTypes.array,
  currencyOptions: PropTypes.array,
  jiraIdOptions: PropTypes.array,
  availableCostTypes: PropTypes.array,
  glAccountOptions: PropTypes.array,
  centersAllowedCombinations: PropTypes.array,
  getValueError: PropTypes.func,
}
