const _ = require('lodash')

module.exports = _.reduce([
  'uuid',
  'center_split',
  'note',
  'taxable',
  'cardholder',
  'createdAt',
  'files',
], (a, v, k) => {a[v] = k; return a}, {})
