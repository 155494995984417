import React, {useState, useEffect, useContext} from 'react'
import _ from 'lodash'
import {makeStyles} from '@material-ui/core/styles'
import {Container} from '@material-ui/core'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import './App.css'
import {loadData} from './communication'
import {AddStatementPage} from './pages/AddStatementPage'
import {HistoryPage} from './pages/HistoryPage'
import {HomePage} from './pages/HomePage'
import {LoadingProvider, LoadingContext} from './context/LoadingContext'
import {ResultProvider, ResultContext} from './context/ResultContext'
import {LoadingAnimation} from './components/LoadingAnimation'
import {TopBar} from './components/TopBar'
import {ManualPairingPage} from './pages/ManualPairingPage'

const useStyles = makeStyles({
  app: {
    height: '100vh',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  mainGrid: {
    maxHeight: '100%',
    minHeight: 0,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: 25,
    left: 0,
    right: 25,
    margin: '0 0 0 auto',
  },
})

function App() {
  const [data, setData] = useState(null)
  const {startLoading, stopLoading} = useContext(LoadingContext)
  const {result, setResult} = useContext(ResultContext)
  const [withUserChooser, setWithUserChooser] = useState(false)
  const forceFetch = async (inNameOf) => {
    inNameOf = inNameOf || _.get(data, ['inNameOf'], null)
    // If the user selected on TopBar changed, better to drop cache to avoid
    // a confusion until fresh data are loaded:
    if (data?.loadedInNameOf !== inNameOf) setData(null)
    const [newData, result] = await loadData(inNameOf)
    setData(newData)
    setResult(result)
  }
  useEffect(() => {
    async function fetchData() {
      startLoading()
      await forceFetch()
      stopLoading()
    }
    fetchData()
  }, [])
  const classes = useStyles()
  if (data === null) return <LoadingAnimation />
  return (
    <div className={`App ${classes.app}`} style={{height: '100%'}}>
      <LoadingAnimation />
      <Router>
        {result !== null ? <Redirect to="/home" /> : null}
        <TopBar
          data={data}
          forceFetch={forceFetch}
          withUserChooser={withUserChooser}
        />
        <Container className={classes.mainGrid} maxWidth="xl">
          <Switch>
            <Route path="/home">
              <HomePage
                forceFetch={forceFetch}
                data={data}
                setWithUserChooser={setWithUserChooser}
              />
            </Route>
            <Route path="/add-statement">
              <AddStatementPage
                forceFetch={forceFetch}
                data={data}
                setWithUserChooser={setWithUserChooser}
              />
            </Route>
            <Route path="/history">
              <HistoryPage
                forceFetch={forceFetch}
                data={data}
                setWithUserChooser={setWithUserChooser}
              />
            </Route>
            <Route path="/finance-dashboard">
              <ManualPairingPage
                forceFetch={forceFetch}
                data={data}
                setWithUserChooser={setWithUserChooser}
              />
            </Route>
            <Redirect from="/" to={'/home'} />
          </Switch>
        </Container>
      </Router>
    </div>
  )
}

export default function getApp() {
  return (
    <LoadingProvider>
      <ResultProvider>
        <App />
      </ResultProvider>
    </LoadingProvider>
  )
}
