import React from 'react'
import {IconButton} from '@material-ui/core'
import {Link} from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import HistoryIcon from '@material-ui/icons/History'
import PairingDashboard from '@material-ui/icons/OfflinePin'
import {Header} from './Header'
import PropTypes from 'prop-types'

const pages = [
  {
    link: '/home',
    icon: <HomeIcon />,
  },
  {
    link: '/history',
    icon: <HistoryIcon />,
  },
  {
    link: '/finance-dashboard',
    icon: <PairingDashboard />,
  },
]

export const renderPageButtons = (whatToExclude) => {
  const buttons = []
  if (!Array.isArray(whatToExclude)) whatToExclude = [whatToExclude]
  whatToExclude = whatToExclude.filter((x) => (typeof x === 'string'))
  for (const page of pages) {
    if (!whatToExclude.includes(page.link)) {
      buttons.push(
        (<IconButton
            key={`${page.link}-button`}
            variant="contained"
            color="primary"
            component={Link}
            to={page.link}
          >
          {page.icon}
        </IconButton>),
      )
    }
  }
  return buttons
}

const PageSwitcherHeader = (props) => {
  const {exclude, children} = props
  return (
    <Header>
      <div />
      {children}
      {exclude !== '*' && (<span>{renderPageButtons(exclude)}</span>)}
    </Header>
  )
}

PageSwitcherHeader.propTypes = {
  exclude: PropTypes.any,
  children: PropTypes.node,
}

export {PageSwitcherHeader}
