import React, {Component} from 'react'
import {PropTypes} from 'prop-types'

const LoadingContext = React.createContext()

class LoadingProvider extends Component {
  state = {
    isLoading: false,
  }

  startLoading = () => {
    this.setState({isLoading: true})
  }

  stopLoading = () => {
    this.setState({isLoading: false})
  }

  render() {
    return (
      <LoadingContext.Provider
        value={{
          isLoading: this.state.isLoading,
          startLoading: this.startLoading,
          stopLoading: this.stopLoading,
        }}
      >
        {this.props.children}
      </LoadingContext.Provider>
    )
  }
}

LoadingProvider.propTypes = {
  children: PropTypes.node,
}

export {LoadingContext, LoadingProvider}
