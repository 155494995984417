import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'
import {Snackbar} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: '#ffe9ec',
    padding: theme.spacing(1),
    fontFamily: 'Monospace',
  },
}))

const Toast = (props) => {
  const {message, onClose, severity, title} = props
  const classes = useStyles()
  return (
    <Snackbar
      anchorOrigin={{vertical:'top', horizontal:'center'}}
      open={!!message}
      onClose={onClose}
      autoHideDuration={severity === 'success' ? 3000: 5000}
      transitionDuration={0}
    >
      <Alert onClose={onClose} severity={severity}>
        <AlertTitle>{title ?? (severity === 'error' ? 'Error:': null)}</AlertTitle>
        {(severity === 'error' ? (<div className={classes.error}>{message}</div>) : message)}
      </Alert>
    </Snackbar>
  )
}

Toast.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  severity: PropTypes.string,
  title: PropTypes.string,
}

export default Toast
