const _ = require('lodash')

module.exports = _.reduce([
  'uuid',
  'card',
  'entity',
  'date',
  'amount',
  'amountCurrency',
  'currency',
  'counterparty',
  'reference',
  'description',
  'gl_account',
  'reversed',
  'jiraId',
  'bank',
  'rejectionReason',
], (a, v, k) => {a[v] = k; return a}, {})
