const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const formatTimestamp = (dateString) => {
  const [year, month, day] = dateString.substring(0, 10).split('-').map(Number)
  return `${day} ${months[month - 1]} ${year}`
}

const I = (options) => new Intl.NumberFormat('en-US', options).format
const priceI = I({minimumFractionDigits: 2, maximumFractionDigits: 2})
export const priceFormat = (price) => priceI(price)

export const decimalFloat = (f, decimals=2) => parseFloat(f.toFixed(decimals))
