import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {FormControl, InputLabel, MenuItem, Select, Chip} from '@material-ui/core'
import {PropTypes} from 'prop-types'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))


const MultiSelect = ({label, options, setSelected, selected}) => {
  const classes = useStyles()
//   const [selected, setSelected] = useState([])

  const handleSelect = (event) => {
    const value = event.target.value
    setSelected(value)
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="multi-select-label">{label}</InputLabel>
        <Select
          labelId="multi-select-label"
          id="multi-select"
          multiple
          value={selected}
          onChange={handleSelect}
          inputProps={{'aria-label': 'select'}}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

MultiSelect.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelected: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default MultiSelect
